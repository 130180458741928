<template>
  <div class="hold-transition">
    <div class="modal fade" id="modal-form-detalle-cssolicitud">
      <div class="modal-dialog modal-xl" v-if="data_solicitud">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">Detalle Solicitud</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-2">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#Solicitud"
                  >Solicitud</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#Autorizacion"
                  v-if="Object.keys(data_solicitud.autorizacion).length > 0"
                  >Autorizaciones
                </a>
              </li>
            </ul>
            <div class="tab-content p-3" id="myTabContent">
              <div id="Solicitud" class="tab-pane fade active show">
                <div class="row">
                  <div class="col-md-6">
                    <!-- Detalle Solicitud -->
                    <div class="card">
                      <div class="card-header bg-light">
                        <h5 class="card-title">Información</h5>
                        <div class="card-tools">
                          <span
                            class="badge"
                            :class="
                              data_solicitud.estado == 1
                                ? 'bg-warning'
                                : data_solicitud.estado == 2
                                ? 'bg-success'
                                : data_solicitud.estado == 3
                                ? 'bg-danger'
                                : data_solicitud.estado == 4
                                ? 'bg-info'
                                : 'bg-secondary'
                            "
                          >
                            {{ data_solicitud.nEstado }}
                          </span>
                        </div>
                      </div>
                      <div class="card-body text-muted">
                        <ul class="fa-ul mb-0">
                          <li>
                            <span class="fa-li">
                              <i class="fas fa-lg fa-chalkboard-teacher"></i>
                            </span>
                            <strong>Solicitud - {{ data_solicitud.id }}</strong>
                            <div class="row">
                              <div class="col-md-5">Tipo Solicitud:</div>
                              <div class="col-md-7 mb-1">
                                <strong>{{
                                  data_solicitud
                                    ? data_solicitud.tipoSolicitud
                                    : ""
                                }}</strong>
                              </div>
                            </div>
                            <div
                              class="row"
                              v-if="data_solicitud.tipoEmergencia"
                            >
                              <div class="col-md-5">Tipo Emergencia:</div>
                              <div class="col-md-7 mb-1">
                                <a
                                  v-if="data_solicitud.archivo_emergencia"
                                  :href="
                                    this.$parent.uri_docs +
                                    data_solicitud.archivo_emergencia
                                  "
                                  target="_blank"
                                  class="text-muted mr-2"
                                >
                                  <i class="fas fa-download"></i>
                                </a>
                                <span
                                  class="badge"
                                  :class="
                                    data_solicitud.tipo_emergencia == 1
                                      ? 'bg-info'
                                      : data_solicitud.tipo_emergencia == 2
                                      ? 'bg-maroon'
                                      : 'bg-secondary'
                                  "
                                >
                                  {{ data_solicitud.tipoEmergencia }}
                                </span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">Fecha:</div>
                              <div class="col-md-7 mb-1">
                                <strong>{{ data_solicitud.fecha }}</strong>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">
                                Material de Inventarios:
                              </div>
                              <div class="col-md-7 mb-1">
                                <div>
                                  <i
                                    class="fa fa-check text-success"
                                    v-if="
                                      data_solicitud.material_inventarios == 1
                                    "
                                  ></i>
                                  <i
                                    class="fa fa-times-circle text-danger"
                                    v-else
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">Usuario Solicitante:</div>
                              <div class="col-md-7 mb-1">
                                <strong>{{
                                  data_solicitud.user
                                    ? data_solicitud.user.name
                                    : ""
                                }}</strong>
                              </div>
                            </div>
                            <div class="row" v-if="data_solicitud.gerente_area">
                              <div class="col-md-5">Gerente Área:</div>
                              <div class="col-md-7 mb-1">
                                <strong>{{
                                  data_solicitud.gerente_area.nombres
                                }}</strong>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">
                                Presupuesto Verificado:
                              </div>
                              <div class="col-md-7 mb-1">
                                <i
                                  class="fa"
                                  :class="
                                    data_solicitud.presupuesto_verificado
                                      ? 'fa-check text-success'
                                      : 'fa-times-circle text-danger'
                                  "
                                ></i>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">Estado:</div>
                              <div class="col-md-7 mb-1">
                                <span
                                  class="badge"
                                  :class="
                                    data_solicitud.estado == 1
                                      ? 'bg-warning'
                                      : data_solicitud.estado == 2
                                      ? 'bg-success'
                                      : data_solicitud.estado == 3
                                      ? 'bg-danger'
                                      : data_solicitud.estado == 4
                                      ? 'bg-lime'
                                      : 'bg-secondary'
                                  "
                                >
                                  {{ data_solicitud.nEstado }}
                                </span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">Justificación:</div>
                              <div class="col-md-7 mb-1">
                                <small>{{
                                  data_solicitud.justificacion
                                }}</small>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-5">Observaciones:</div>
                              <div class="col-md-7">
                                <small>{{
                                  data_solicitud.observaciones
                                }}</small>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <!-- Presupuestos -->
                    <div
                      class="card"
                      v-if="Object.keys(data_solicitud.presupuestos).length > 0"
                    >
                      <div class="card-header bg-light">
                        <h5 class="card-title">Presupuestos</h5>
                        <div class="card-tools"></div>
                      </div>
                      <div class="card-body p-0">
                        <table
                          class="table table-bordered table-striped table-hover table-sm mb-0"
                          style="font-size: 0.85em"
                        >
                          <thead>
                            <tr>
                              <th>Área</th>
                              <th>Tipo Presupuesto</th>
                              <th>%</th>
                              <th>Responsable</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="presup in data_solicitud.presupuestos"
                              :key="presup.id"
                            >
                              <td>{{ presup.nArea }}</td>
                              <td>
                                <span
                                  class="badge"
                                  :class="
                                    presup.tipo_presupuesto == 1
                                      ? 'bg-info'
                                      : presup.tipo_presupuesto == 2
                                      ? 'bg-navy'
                                      : 'bg-secondary'
                                  "
                                >
                                  {{ presup.tipoPresupuesto }}
                                </span>
                              </td>
                              <td class="text-right">
                                {{ presup.porcentaje }}%
                              </td>
                              <td>
                                {{
                                  presup.responsable
                                    ? presup.responsable.nombres +
                                      " " +
                                      presup.responsable.apellidos
                                    : ""
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="card card-info card-outline collapsed-card"
                      v-for="item in data_solicitud.tipos_servicios"
                      :key="item.id"
                    >
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <i class="fa fa-truck text-secondary"></i> Viaje
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                            class="btn btn-tool"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12">
                            <!-- Rutas -->
                            <div
                              class="card"
                              v-if="Object.keys(item.rutas).length > 0"
                            >
                              <div class="card-header bg-light">
                                <h5 class="card-title">Rutas</h5>
                                <div class="card-tools"></div>
                              </div>
                              <div class="card-body p-0">
                                <table
                                  class="table table-bordered table-striped table-hover table-sm mb-0"
                                  style="font-size: 0.85em"
                                >
                                  <thead>
                                    <tr>
                                      <th>Tipo Sitio</th>
                                      <th>Nombre</th>
                                      <th>Direccion</th>
                                      <th>Nombre Contacto</th>
                                      <th>Tel Contacto</th>
                                      <th>Email Contacto</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="ruta in item.rutas"
                                      :key="ruta.id"
                                    >
                                      <td>
                                        <span
                                          class="badge"
                                          :class="
                                            ruta.tipo_sitio == 1
                                              ? 'bg-info'
                                              : ruta.tipo_sitio == 2
                                              ? 'bg-navy'
                                              : 'bg-secondary'
                                          "
                                        >
                                          {{ ruta.nTipoSitio }}
                                        </span>
                                      </td>
                                      <td>{{ ruta.nombre }}</td>
                                      <td>{{ ruta.direccion }}</td>
                                      <td>{{ ruta.nombre_contacto }}</td>
                                      <td>{{ ruta.telefono_contacto }}</td>
                                      <td>{{ ruta.email_contacto }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- Tipo vehiculo -->
                            <div
                              class="card"
                              v-if="Object.keys(item).length > 0"
                            >
                              <div class="card-header bg-light">
                                <h5 class="card-title">Detalles</h5>
                                <div class="card-tools"></div>
                              </div>
                              <div class="card-body p-0">
                                <table
                                  class="table table-bordered table-striped table-hover table-sm mb-0"
                                  style="font-size: 0.85em"
                                >
                                  <thead>
                                    <tr>
                                      <th>Tipo Servicio</th>
                                      <th>Vehiculo/Equipo/Complementarios</th>
                                      <th>Día/Viaje</th>
                                      <th>Cantidad Vehículos a Solicitar</th>
                                      <th>
                                        Tiempo Requerido del Servicio (Dias /
                                        Cant)
                                      </th>
                                      <th>Transporta Material</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {{ item.tipoServicio }}
                                      </td>
                                      <td>
                                        <div v-if="item.tipo_servicio == 1">
                                          {{ item.tipo_vehiculo.nombre }}
                                          {{
                                            item.tipoRemolque
                                              ? " - " + item.tipoRemolque
                                              : ""
                                          }}
                                        </div>
                                        <div
                                          v-else-if="item.tipo_servicio == 2"
                                        >
                                          {{ item.nTipoEquipo }}
                                        </div>
                                        <div
                                          v-else-if="item.tipo_servicio == 3"
                                        >
                                          {{ item.tipoComplemen }}
                                        </div>
                                      </td>
                                      <td>
                                        <div v-if="item.tipo_cantidad == 1">
                                          <span class="badge bg-lime"
                                            >Días</span
                                          >
                                        </div>
                                        <div v-else>
                                          <span class="badge bg-navy"
                                            >Viajes</span
                                          >
                                        </div>
                                      </td>
                                      <td>
                                        {{ item.cantidad_vehiculos }}
                                      </td>
                                      <td>
                                        {{ item.cantidad }}
                                      </td>
                                      <td>
                                        <div
                                          v-if="item.material_inventarios == 1"
                                        >
                                          <span class="badge badge-success"
                                            >Si</span
                                          >
                                        </div>
                                        <div v-else>
                                          <span class="badge badge-danger"
                                            >No</span
                                          >
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- Detalles -->
                            <div
                              class="card"
                              v-if="Object.keys(item.det_cargas).length > 0"
                            >
                              <div class="card-header bg-light">
                                <h5 class="card-title">Detalles</h5>
                                <div class="card-tools"></div>
                              </div>
                              <div class="card-body p-0">
                                <table
                                  class="table table-bordered table-striped table-hover table-sm mb-0"
                                  style="font-size: 0.85em"
                                >
                                  <thead>
                                    <tr>
                                      <th>No. Pedido</th>
                                      <th>Posición</th>
                                      <th>Material</th>
                                      <th>
                                        Largo x Ancho x Alto
                                        <small>(mts)</small>
                                      </th>
                                      <th>Peso <small>(Kg)</small></th>
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="det in item.det_cargas"
                                      :key="det.id"
                                    >
                                      <td>{{ det.no_pedido_compra }}</td>
                                      <td>{{ det.pos_pedido }}</td>
                                      <td class="text-left text-nowrap">
                                        {{ det.descripcion_material }}
                                      </td>
                                      <td>
                                        {{
                                          `${det.largo} x ${det.ancho} x ${det.alto}`
                                        }}
                                      </td>
                                      <td>{{ det.peso }}</td>
                                      <td>
                                        {{ `${det.valor} (${det.nMoneda})` }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="Autorizacion"
                class="tab-pane"
                v-if="Object.keys(data_solicitud.autorizacion).length > 0"
              >
                <table
                  class="table table-bordered table-striped table-hover table-sm mb-0"
                  style="font-size: 0.85em"
                >
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Funcionario</th>
                      <th>Fecha Autorizo</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="aut in data_solicitud.autorizacion"
                      :key="aut.id"
                    >
                      <td>{{ aut.tipoAutorizacion }}</td>
                      <td>
                        {{ aut.user ? aut.user.name : aut.user_autoriza.name }}
                      </td>
                      <td>{{ aut.fecha_autorizacion }}</td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            aut.estado == 1
                              ? 'bg-info'
                              : aut.estado == 2
                              ? 'bg-success'
                              : aut.estado == 3
                              ? 'bg-danger'
                              : 'bg-secondary'
                          "
                        >
                          {{ aut.nEstado }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CsSolicitudDetalle",
  components: {},
  data() {
    return {
      data_solicitud: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    llenar_modal(item) {
      this.data_solicitud = item;
    },

    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },
  },
};
</script>
